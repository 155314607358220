import cx from 'classnames';

interface Props {
  label?: string;
  className?: string;
  onClick?: VoidFunction;
  variant?: 'down' | 'up';
  href?: string;
}

const variants = {
  down: {
    bg: 'bg-white',
    text: 'text-dark',
    stroke: 'stroke-dark',
    hoverBg: 'group-hover:bg-dark',
    hoverText: 'group-hover:text-white',
    hoverStroke: 'group-hover:stroke-white',
    hoverRotate: 'group-hover:rotate-45',
  },
  up: {
    bg: 'bg-white',
    text: 'text-dark',
    stroke: 'stroke-dark',
    hoverBg: 'group-hover:bg-accent',
    hoverText: 'group-hover:text-dark',
    hoverStroke: 'group-hover:stroke-dark',
    hoverRotate: 'group-hover:-rotate-45',
  },
};

function LinkButton(props: Props) {
  const { variant = 'down' } = props;
  const variantClasses = variants[variant];

  const button = (
    <>
      <span
        className={cx(
          'transition-colors duration-300 text-body1 min-h-[3.875rem] px-8 flex justify-center items-center rounded-[3.75rem] relative z-10',
          variantClasses.bg,
          variantClasses.text,
          variantClasses.hoverBg,
          variantClasses.hoverText,
        )}
      >
        {props.label}
      </span>
      <div className="hidden desktop:block transition-transform duration-500 top-0 right-0 group-hover:translate-x-[5.75rem] absolute z-0">
        <div
          className={cx(
            'transition-colors duration-300 min-h-[3.875rem] px-8 rounded-[3.75rem] inline-flex justify-center items-center',
            variantClasses.bg,
            variantClasses.hoverBg,
          )}
        >
          <span className="rotate-180">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              className={cx(
                'transition-all duration-500',
                variantClasses.stroke,
                variantClasses.hoverStroke,
                variantClasses.hoverRotate,
              )}
            >
              <path
                strokeWidth="1.6"
                d="M19.6055 10.6067L1.60754 10.6067M1.60754 10.6067L6.7504 4.60669M1.60754 10.6067L6.7504 16.6067"
              />
            </svg>
          </span>
        </div>
      </div>
    </>
  );

  return props.href ? (
    <a
      className={cx('group relative inline-block w-fit', props.className)}
      href={props.href}
    >
      {button}
    </a>
  ) : (
    <button
      className={cx('group relative w-fit', props.className)}
      onClick={props.onClick}
    >
      {button}
    </button>
  );
}

export default LinkButton;
