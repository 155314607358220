import type { ApiBlogBlog } from '@eva/cms-types';

import Button from './Button';
import NewsCard from '../NewsCard';
import LinkButton from '../LinkButton';
import { Direction } from 'apps/web/src/lib/types';
import useCarousel from '../../../hooks/react/useCarousel';

interface Props {
  cards: ApiBlogBlog[];
  ctaText?: string;
}

function Carousel(props: Props) {
  const {
    containerRef,
    handleArrowClick,
    leftArrowActive,
    rightArrowActive,
    scrollable,
  } = useCarousel();

  return (
    <>
      <div className="max-w-content px-6 mx-auto overflow-visible flex justify-between">
        <LinkButton label={props.ctaText} href="/blog" />
        {scrollable && (
          <div className="hidden desktop:flex gap-2">
            <Button
              onclick={handleArrowClick}
              direction={Direction.left}
              disabled={!leftArrowActive}
            />
            <Button
              onclick={handleArrowClick}
              direction={Direction.right}
              disabled={!rightArrowActive}
            />
          </div>
        )}
      </div>
      <div className="grid grid-cols-content mt-8 mb-[7.75rem]">
        <div
          className="overflow-x-scroll col-[2/3_span] snap-x snap-proximity flex gap-2 scroll-smooth pb-4"
          ref={containerRef}
        >
          {props.cards.map((card, index) => (
            <NewsCard card={card.attributes} key={index} />
          ))}
        </div>
      </div>
    </>
  );
}

export default Carousel;
