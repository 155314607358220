import type { ApiBlogBlog } from '@eva/cms-types';

import { getStringData, getUploadFileUrl } from '../../lib/strapi';

interface Props {
  card: ApiBlogBlog['attributes'];
}

function NewsCard(props: Props) {
  const { url: imageUrl } = getUploadFileUrl(props.card.image);
  const { title, publishedAt, slug } = getStringData(props.card);

  return (
    <a
      href={`/blog/${slug}`}
      className="flex-1 min-w-[280px] desktop:min-w-[36.5rem] snap-start aspect-[2/3] desktop:aspect-[4/3] pr-2"
    >
      <div className="h-full rounded-3xl bg-no-repeat bg-cover flex items-end relative overflow-hidden group">
        <div
          style={{ backgroundImage: `url(${imageUrl})` }}
          className="absolute w-full h-full bg-cover bg-center group-hover:scale-110 transition-all duration-1000 ease-in-out z-0"
        ></div>
        <div className="absolute z-0 bg-gradient-to-t from-black desktop:via-transparent to-transparent h-full w-full"></div>
        <div className="mt-auto px-4 py-8 desktop:p-8 relative z-10 w-full flex flex-col gap-4">
          <h3 className="text-h4 text-white">{title}</h3>
          <div className="text-white border-2 border-white border-solid w-fit p-3 rounded-full">
            {new Date(publishedAt!).toLocaleDateString('cs-CZ')}
          </div>
        </div>
      </div>
    </a>
  );
}

export default NewsCard;
