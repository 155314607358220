import ReactDOM from 'react-dom';
import { useEffect, useRef, useState } from 'react';

import { Direction } from '../../lib/types';

const calculatSteps = (items: NodeListOf<HTMLElement>) => {
  const steps: number[] = [];
  items.forEach((item) => {
    steps.push(item.offsetWidth);
  });
  return steps;
};

const useCarousel = () => {
  const containerRef = useRef<HTMLDivElement>();
  const [leftArrowActive, setLeftArrowActive] = useState(false);
  const [rightArrowActive, setRightArrowActive] = useState(true);
  const [scrollable, setScrollable] = useState(false);
  const stepRef = useRef(0);
  const stepsRef = useRef(null);
  const prevScroll = useRef(0);
  const rightOffset = 16;

  useEffect(() => {
    if (!containerRef.current || typeof window === 'undefined') return;

    const childItems = containerRef.current
      .childNodes as NodeListOf<HTMLElement>;

    stepsRef.current = calculatSteps(childItems);

    const handleScroll = () => {
      const scrollLeft = containerRef.current.scrollLeft;
      const scrollWidth = containerRef.current.scrollWidth;

      setLeftArrowActive(scrollLeft > 16);
      setRightArrowActive(
        Math.ceil(scrollLeft + rightOffset + containerRef.current.clientWidth) <
          scrollWidth,
      );
    };

    containerRef.current?.addEventListener('scroll', handleScroll);

    return () =>
      containerRef.current?.removeEventListener('scroll', handleScroll);
  }, [containerRef.current]);

  useEffect(() => {
    if (typeof window === 'undefined' || !containerRef.current) return;

    const handleResize = () => {
      const scrollWidth = containerRef.current.scrollWidth;
      const clientWidth = containerRef.current.clientWidth;

      if (scrollWidth <= clientWidth) {
        setScrollable(false);
      } else {
        setScrollable(true);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleArrowClick = (direction: Direction) => {
    if (!containerRef.current) return;

    const steps = stepsRef.current;
    const scrollLeft = containerRef.current.scrollLeft;

    const gap = parseInt(
      window
        .getComputedStyle(ReactDOM.findDOMNode(containerRef.current) as Element)
        .getPropertyValue('gap'),
    );

    prevScroll.current = containerRef.current.scrollLeft;

    switch (direction) {
      case Direction.left:
        if (stepRef.current >= 0)
          containerRef.current.scrollLeft =
            scrollLeft - steps[--stepRef.current] / 2 - gap;
        break;
      case Direction.right:
        containerRef.current.scrollLeft =
          scrollLeft + steps[stepRef.current++] / 2 + gap;
        break;
    }

    if (stepRef.current >= stepsRef.current.length) {
      stepRef.current = stepsRef.current.length - 1;
    }

    if (stepRef.current < 0) {
      stepRef.current = 0;
    }
  };

  return {
    containerRef,
    handleArrowClick,
    leftArrowActive,
    rightArrowActive,
    scrollable,
  };
};

export default useCarousel;
